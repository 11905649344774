<template>
  <div>
    <!-- BREADCRUMB -->
    <breadcrumb-base :breadcrumb="breadcrumb" :title="'Reclamation'">
    </breadcrumb-base>

    <transition name="fade">
      <div class="content-center" v-if="reclaim">
        <div class="vx-col md:w-4/5 w-full mb-base c-center py-2">
          <vs-alert class="w-full" :active="reclaim && reclaim.again_reclaim_alert" color="danger"
                    icon="new_releases">
            <span>{{ $t('SameBatchPinReclaimed') }}</span>
          </vs-alert>
        </div>
        <div class="vx-col md:w-4/5 w-full mb-base c-center">
          <vx-card :title="reclaim.product + ' - (' + getNumberFormat(reclaim.denomination) + ')'">

            <div class="vx-row" v-if="reclaim">
              <div class="vx-col md:w-1/3">
                <div class="mt-4">
                  <p class="customer-text-heading">{{ $t('Cusomer') }}</p>
                  <p class="customer-text">
                    {{ reclaim.customer_id }} - {{ reclaim.organisation_name }}
                  </p>
                </div>
                <div class="mt-4">
                  <p class="customer-text-heading">{{ $t('ReclamationStatus') }}</p>
                  <div class="flex">
                    <vs-chip :color="getStatusColor(reclaim.status)" class="user-status">{{
                        getStatusText(reclaim.status) | title
                      }}
                    </vs-chip>
                  </div>
                </div>
                <div class="mt-4">
                  <p class="customer-text-heading">{{ $t('ReclaimMessage') }}</p>
                  <p class="customer-text">{{ reclaim.clam_message }}</p>
                </div>

                <template v-if="reclaim.parent_organisation_type == 1">
                  <div class="mt-4">
                    <p class="customer-text-heading">{{ $t('Reseller') }}</p>
                    <p class="customer-text">
                      {{ reclaim.reseller_customer_id }} - {{ reclaim.reseller_name }}
                    </p>
                  </div>
                </template>
              </div>
              <div class="vx-col md:w-1/3">
                <div class="mt-4">
                  <p class="customer-text-heading">{{ $t('FirstPurchasedCustomer') }}</p>
                  <p class="customer-text">
                    {{ reclaim.first_purchased_customer_id }} - {{ reclaim.first_purchased_by }}
                  </p>
                </div>
                <div class="mt-4">
                  <p class="customer-text-heading">{{ $t('FirstPurchasedCustomerTime') }}</p>
                  <p class="customer-text">{{ reclaim.first_purchased_at | dateFormat }}</p>
                </div>
                <div class="mt-4">
                  <p class="customer-text-heading">{{ $t('Batch') }}</p>
                  <p class="customer-text">{{ reclaim.batch }}</p>
                </div>
                <div class="mt-4">
                  <p class="customer-text-heading">{{ $t('Pin') }}</p>
                  <p class="customer-text">{{ reclaim.pin }}
                  </p>
                </div>
                <div class="mt-4">
                  <p class="customer-text-heading">{{ $t('TransactionDate') }}</p>
                  <p class="customer-text">{{ reclaim.transaction_at | dateFormat }}</p>
                </div>
                <div class="mt-4">
                  <p class="customer-text-heading">{{ $t('Remarks') }}</p>
                  <p class="customer-text">{{ reclaim.remark }}</p>
                </div>
              </div>
              <div class="vx-col md:w-1/3">

                <div class="mt-4">
                  <p class="customer-text-heading">{{ $t('SupplierCompany') }}</p>
                  <p class="customer-text">{{ reclaim.supplier_company }}</p>
                </div>
                <div class="mt-4">
                  <p class="customer-text-heading">{{ $t('ResoldToCustomer') }}</p>
                  <p class="customer-text">
                    {{ reclaim.last_resold_to_customer_id }} - {{ reclaim.last_resold_to }}
                  </p>
                </div>
                <div class="mt-4">
                  <p class="customer-text-heading">{{ $t('ResoldAt') }}</p>
                  <p class="customer-text">{{ reclaim.last_resold_at | dateFormat }}</p>
                </div>
                <div class="mt-4">
                  <p class="customer-text-heading">{{ $t('ReclaimedAfter') }}</p>
                  <p class="customer-text">{{
                      getTimeDiff()
                    }}
                  </p>
                </div>
                <div class="mt-4">
                  <p class="customer-text-heading">{{ $t('Created') }}</p>
                  <p class="customer-text">{{ reclaim.created | dateFormat }}</p>
                </div>
                <div class="mt-4">
                  <p class="customer-text-heading">{{ $t('LastUpdated') }}</p>
                  <p class="customer-text">{{ reclaim.updated | dateFormat }}
                  </p>
                </div>
              </div>
            </div>

            <div class="vx-row mt-8" v-if="reclaim.status === 0">
              <div class="p-3"></div>
              <vs-button @click="showDirectCreditNoteDialog = true" color="success" type="filled">{{ $t('DirectCreditNote') }}
              </vs-button>

              <vs-spacer></vs-spacer>
              <div class="p-3"></div>
              <vs-button @click="updateReclaimStatus(1)" color="success" type="filled">{{ $t('Accept') }}
              </vs-button>
              <div class="p-3"></div>
              <vs-button @click="showRejectConfirmDialog = true" color="danger" type="filled">{{ $t('Reject') }}
              </vs-button>

              <div class="p-3"></div>
            </div>

            <div class="vx-row mt-8" v-if="reclaim.status === 2">

              <div class="p-3"></div>
              <vs-button @click="showRejectConfirmDialog = true" color="danger" type="filled">{{ $t('Reject') }}
              </vs-button>

              <vs-spacer></vs-spacer>
              <div class="p-3"></div>
            </div>
            <div class="vx-row mt-8" v-if="reclaim.status === 1">

              <div class="p-3"></div>
              <vs-button @click="showDirectCreditNoteDialog = true" color="success" type="filled">{{ $t('DirectCreditNote') }}
              </vs-button>
              <div class="p-3"></div>
              <vs-button @click="showRejectConfirmDialog = true" color="danger" type="filled">{{ $t('Reject') }}
              </vs-button>

              <vs-spacer></vs-spacer>
              <div class="p-3"></div>
            </div>

            <div class="vx-row mt-8" v-if="reclaim.status === 2">
              <vs-spacer></vs-spacer>
              <div class="p-3"></div>
              <vs-button @click="updateReclaimStatus(3)" color="success" type="filled">{{ $t('Issue Credit Note') }}
              </vs-button>

              <div class="p-3"></div>
            </div>


          </vx-card>
        </div>
        <div
          v-if="reclaim && reclaim.previous_record_details"
          class="vx-col md:w-4/5 w-full c-center" style="margin-bottom: 10px; margin-top: 10px">
          <p class="customer-text">{{ $t('PreviousReclaim') }}</p>
        </div>
        <div class="vx-col md:w-4/5 w-full mb-base c-center my-4" v-if="reclaim && reclaim.previous_record_details">
          <vs-table-modified v-show="reclaim.previous_record_details" ref="table"
                             :data="[reclaim.previous_record_details]">

            <template slot="thead">
              <vs-th>{{ $t('CustomerId') }}</vs-th>
              <vs-th>{{ $t('Customer') }}</vs-th>
              <vs-th>{{ $t('Product') }}</vs-th>
              <vs-th>{{ $t('Status') }}</vs-th>
              <vs-th>{{ $t('Batch') }}</vs-th>
              <vs-th>{{ $t('Pin') }}</vs-th>
              <vs-th>{{ $t('Created') }}</vs-th>
              <vs-th>{{ $t('LastUpdated') }}</vs-th>
            </template>

            <template slot-scope="{data}">
              <tbody>
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">

                <vs-td>
                  <div>
                    <p class="user-name font-medium truncate">{{ tr.customer_id }}</p>
                    <p class="font-medium truncate">{{ tr.organisation_name }}</p>
                  </div>
                </vs-td>
                <vs-td>
                  <div>
                    <p class="font-medium truncate">{{ tr.first_name }} {{ tr.last_name }}</p>
                    <p style="font-size: 11px; ">
                      {{ tr.address }}<span v-if="tr.house_no"> {{ tr.house_no }}</span>
                    </p>

                    <p style="font-size: 11px;">
                      {{ tr.postal }} {{ tr.city }}
                    </p>
                  </div>
                </vs-td>
                <vs-td>
                  <p class="user-name font-medium truncate">{{ tr.product }} -
                    {{ tr.denomination | germanNumberFormat }}</p>
                </vs-td>

                <vs-td>
                  <div class="text-center flex flex-col justify-center">
                    <div class="tooltip">
                      <vs-chip :color="getStatusColor(tr.status)" class="user-status">{{
                          getStatusText(tr.status) | title
                        }}
                      </vs-chip>
                      <span class="tooltiptext tooltip-top"
                            v-if="tr.clam_message">{{ tr.clam_message }}</span>
                    </div>
                  </div>
                </vs-td>
                <vs-td>
                  <p class="user-name font-medium truncate">{{ tr.batch }}</p>
                </vs-td>
                <vs-td>
                  <p class="user-name font-medium truncate">{{ tr.pin }}</p>
                </vs-td>
                <vs-td>
                  <p class="user-name font-medium truncate"
                     style="max-width: 100px;white-space: normal !important;padding: 0 8px;"
                  >{{ tr.created | dateFormat }}</p>
                </vs-td>
                <vs-td>
                  <p class="user-name font-medium truncate"
                     style="max-width: 100px;white-space: normal !important;padding: 0 8px;"
                  >{{ tr.updated | dateFormat }}</p>
                </vs-td>


                <!--                    <vs-td class="whitespace-no-wrap vx-row">-->

                <!--                      <vs-button @click="onUpdatePressed(tr)">{{ $t('Update') }}</vs-button>-->
                <!--                    </vs-td>-->

              </vs-tr>
              </tbody>
            </template>
            <template slot="footer">
            </template>
          </vs-table-modified>
        </div>
      </div>
    </transition>

    <vx-modal v-if="showRejectMessageModal" @close="showRejectMessageModal = false"
              component-class="modal-container-fit">
      <template slot="header"></template>
      <template slot="body">
        <div class="p-2 flex flex-centered">
          <vs-col>
            <h3>{{ $t('RejectReason') }}</h3>
            <div class="p-2"></div>

            <vs-textarea :label="$t('Reason')"
                         autofocus
                         v-model="remarks"/>

            <div class="vs-row flex justify-center mt-3">
              <vs-button class="ml-2 mt-3" color="primary" @click="updateReject()">{{ $t('Submit') }}</vs-button>
              <vs-button class="ml-4 mt-3" color="danger" @click="showRejectMessageModal = false">{{
                  $t('Close')
                }}
              </vs-button>
            </div>
          </vs-col>
        </div>
      </template>
    </vx-modal>
    <vx-modal v-if="showRejectConfirmDialog" @close="showRejectConfirmDialog = false"
              component-class="modal-container-fit">
      <template slot="header"></template>
      <template slot="body">
        <div class="p-2 flex flex-centered">
          <vs-col>
            <div class="p-2"></div>
            <p>{{ $t('confirmRejection') }}</p>
            <div class="p-2"></div>
            <vs-textarea :label="$t('Reason')"
                         autofocus
                         v-model="remarks"/>

            <div class="vs-row flex justify-center mt-3">
              <vs-button class="ml-2 mt-3" color="primary" @click="updateReclaimStatus(5)">{{ $t('Reject') }}</vs-button>
              <vs-button class="ml-4 mt-3" color="danger" @click="showRejectConfirmDialog = false">{{
                  $t('Close')
                }}
              </vs-button>
            </div>
          </vs-col>
        </div>
      </template>
    </vx-modal>
    <vx-modal v-if="showDirectCreditNoteDialog" @close="showDirectCreditNoteDialog = false"
              component-class="modal-container-fit">
      <template slot="header"></template>
      <template slot="body">
        <div class="p-2 flex flex-centered">
          <vs-col>
            <div class="p-2"></div>
            <div class="p-2"></div>
            <vs-textarea :label="$t('Remark')"
                         autofocus
                         v-model="remarks"/>

            <div class="vs-row flex justify-center mt-3">
              <vs-button class="ml-2 mt-3" color="primary" @click="updateReclaimStatus(6)">{{ $t('DirectCreditNote') }}</vs-button>
              <vs-button class="ml-4 mt-3" color="danger" @click="showDirectCreditNoteDialog = false">{{
                  $t('Close')
                }}
              </vs-button>
            </div>
          </vs-col>
        </div>
      </template>
    </vx-modal>

  </div>
</template>

<script>
import moment from 'moment'
import VxModal from '@/layouts/components/custom/VxModal'

export default {
  name: 'ReclaimDetails',
  components: {
    VxModal,
  },
  props: {
    reclaimId: {
      type: String | Number,
    },
  },
  computed: {},
  data() {
    return {
      showRejectConfirmDialog: false,
      showDirectCreditNoteDialog: false,
      remarks: '',
      showRejectMessageModal: false,
      reclaim: null,
      breadcrumb: [
        {title: 'Home', i18n: 'Home', url: '/'},
        {title: 'Reclamations', i18n: 'Reclamations', url: '/reclaim-requests'},
        {title: 'Reclamation', i18n: 'Reclamation', active: true},
      ],
    }
  },
  methods: {

    openReclaimDetails(reclaim) {
      // reclamation_id
      this.$router.push({name: 'reclaim-request', params: {reclaimId: reclaim.reclamation_id}})
    },
    getTimeDiff() {
      let start = this.reclaim.transaction_at;
      let end = this.reclaim.created
      return moment.duration(moment(start).diff(moment(end))).humanize()
    },
    getStatusText(status) {
      // 0 - new, 1 - in-progress, 2 - sold, 3 - issue credit note, 5 - reject
      if (status === 0) return this.$t('InReview')
      if (status === 1) return this.$t('InProgress')
      if (status === 2) return this.$t('InProgress')
      if (status === 3) return this.$t('IssueCreditNote')
      if (status === 5) return this.$t('Reject')
      if (status === 6) return this.$t('IssueCreditNote')
      return ''
    },
    getStatusColor(status) {
      // 0 - new, 1 - in-progress, 2 - sold, 3 - issue credit note, 5 - reject
      if (status === 0) return 'danger'
      if (status === 1) return 'warning'
      if (status === 2) return 'success'
      if (status === 3) return 'purple'
      if (status === 5) return 'primary'
      if (status === 6) return 'purple'
      return ''
    },
    getNumberFormat(value) {
      if (value == null || value == undefined || isNaN(value)) return '-'
      const formatter = new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
      })
      return formatter.format(value)
    },
    async updateReject() {
      const payload = {
        update_reclamation_pin: JSON.stringify({
          reclamation_id: this.reclaim.reclamation_id,
          status: 5,
          remarks: this.remarks
        }),
      }
      this.$vs.loading()
      const data = await this.$store.dispatch('reclamation/fetchReclaimRequests', {payload}).catch((error) => {
        this.$vs.loading.close()
        this.showRejectMessageModal = false
        this.remarks = ""
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: error.response.data.message || error.response.statusText,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
        return false
      })
      if (data) {
        this.showRejectMessageModal = false
        this.remarks = ""
        this.$vs.loading.close()
        this.fetchReclaim()
        return true
      }
      this.showRejectMessageModal = false
      this.$vs.loading.close()
      this.$vs.notify({
        position: 'top-center',
        title: 'Error',
        text: 'Unknown error',
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'danger',
      })
    },
    async updateReclaimStatus(status) {
      this.showRejectConfirmDialog = false;
      this.showDirectCreditNoteDialog = false;
      const payload = {
        update_reclamation_pin: JSON.stringify({
          reclamation_id: this.reclaim.reclamation_id,
          status: status,
          remarks: this.remarks
        }),
      }
      this.$vs.loading()
      const data = await this.$store.dispatch('reclamation/fetchReclaimRequests', {payload}).catch((error) => {
        this.$vs.loading.close()
        this.showRejectMessageModal = false
        this.remarks = ""
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: error.response.data.message || error.response.statusText,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
        return false
      })
      if (data) {
        this.showRejectMessageModal = false
        this.remarks = ""
        this.$vs.loading.close()
        this.fetchReclaim()
        return true
      }
      this.showRejectMessageModal = false
      this.$vs.loading.close()
      this.$vs.notify({
        position: 'top-center',
        title: 'Error',
        text: 'Unknown error',
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'danger',
      })
    },
    fetchReclaim() {
      this.$vs.loading()
      return this.$store.dispatch('reclamation/fetchSingleReclaim', this.reclaimId)
        .then((data) => {
          this.reclaim = data
          this.$vs.loading.close()
          this.errorFetching = false
        })
        .catch((error) => {
          console.error(error)
          this.$vs.loading.close()
          this.errorFetching = true
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
  },
  mounted() {
    this.fetchReclaim()
  },
}
</script>

<style>
.customer-text-heading {
  color: #00000085;
}

.customer-text {
  font-size: 20px;
  font-weight: 500;
  word-break: break-all;
}

.c-center {
  margin: auto;
}

.p-type {
  padding: 0 1rem;
  font-size: 10px;
}

.p-type-select {
  padding: 0 0 0 1rem;
}

.vx-card__title > h4 {
  font-weight: bold !important;
}

.upload-btn-wrapper {
  margin: 1rem;
  position: relative;
  display: flex;
}

.upload-contents {
  border: 1px dashed #00000040;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
  color: #2C2C2C;
  background-color: white;
  padding: 30px 30px;
  font-size: 20px;
  font-weight: bold;
  width: 100%;
  margin: auto;
}

.upload-btn-wrapper-denomination {
  position: absolute;
}

.upload-contents-denomination {
  padding: 5px;
}

.upload-btn-wrapper input[type=file] {
  position: absolute;
  margin: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
}

.p-image-holder {
  position: relative;
  display: flex;
}

.p-image {
  width: 90%;
  object-fit: cover;
  max-height: 200px;
  margin: 1rem;
}

.p-delete-button {
  position: absolute;
  float: right;
  right: 0;
  margin: 0.5rem;
}

.p-image-denomination {
  width: 50%;
}
</style>
